import React, { useState } from 'react'
import style from './TerceraParte.module.css'
import { useTranslation } from 'react-i18next'

import logo from '../../../utils/images/logo-ingertec.png'
import logoBr from '../../../utils/images/logo-ingertec-brasil.png'
import imagen1 from '../../../utils/images/Icono-manos.png'
import imagen2 from '../../../utils/images/icono-servicios.png'
import imagen3 from '../../../utils/images/icono-desarrollo.png'
import imagen4 from '../../../utils/images/icono-actualizacion.png'
import imagen5 from '../../../utils/images/icono-soporte.png'

const TerceraParte = () => {
    const [t] = useTranslation();
    const [estado, setEstado] = useState(1)

    const [hover1, setHover1] = useState(false)
    const [hover2, setHover2] = useState(false)
    const [hover3, setHover3] = useState(false)
    const [hover4, setHover4] = useState(false)
    const [hover5, setHover5] = useState(false)

    return (
        <div className={style.container}>
            <div className={style.divIzq}>
                <div className={estado === 1 ? style.divText : style.divNone}>
                    <div className={style.divTitle}>
                        <img className={style.icon} src={imagen1} alt="" />
                        <p className={style.title}>{t("Services.ThirdPart.Stability.title")}</p>
                    </div>
                    <p className={style.text}>{t("Services.ThirdPart.Stability.text")}</p>
                </div>
                <div className={estado === 2 ? style.divText : style.divNone}>
                    <div className={style.divTitle}>
                        <img className={style.icon} src={imagen2} alt="" />
                        <p className={style.title}>{t("Services.ThirdPart.Software-development.title")}</p>
                    </div>
                    <p className={style.text}>{t("Services.ThirdPart.Software-development.text")}</p>
                </div>
                <div className={estado === 3 ? style.divText : style.divNone}>
                    <div className={style.divTitle}>
                        <img className={style.icon} src={imagen3} alt="" />
                        <p className={style.title}>{t("Services.ThirdPart.Web-developments.title")}</p>
                    </div>
                    <p className={style.text}>{t("Services.ThirdPart.Web-developments.text")}</p>
                    <ul className={style.ul}>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list")}</li>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list2")}</li>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list3")}</li>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list4")}</li>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list5")}</li>
                        <li className={style.li}>{t("Services.ThirdPart.Web-developments.list6")}</li>
                    </ul>
                </div>
                <div className={estado === 4 ? style.divText : style.divNone}>
                    <div className={style.divTitle}>
                        <img className={style.icon} src={imagen4} alt="" />
                        <p className={style.title}>{t("Services.ThirdPart.Updates.title")}</p>
                    </div>
                    <p className={style.text}>{t("Services.ThirdPart.Updates.text")}</p>
                </div>
                <div className={estado === 5 ? style.divText : style.divNone}>
                    <div className={style.divTitle}>
                        <img className={style.icon} src={imagen5} alt="" />
                        <p className={style.title}>{t("Services.ThirdPart.Medium.title")}</p>
                    </div>
                    <p className={style.text}>{t("Services.ThirdPart.Medium.text")}</p>
                </div>
            </div>
            <div className={style.divDer}>
                <div className={style.divLogo}>
                    {
                        window.location.origin === "https://pagina-ingertec-2.vercel.app" ?
                        <img className={style.logo} src={logoBr} alt="" /> :
                        <img className={style.logo} src={logo} alt="" />
                    }
                    <div className={style.divOption1}>
                        <div className={style.divOption}>
                            <p className={estado === 1 ? style.optionSelected : style.option} onClick={() => setEstado(1)} onMouseEnter={() => setHover1(true)} onMouseLeave={() => setHover1(false)}>{t("Services.ThirdPart.Stability.title")}</p>
                        </div>
                        <div className={estado === 1 ? style.divIconSelected : hover1 ? style.divIconHover : style.divIcon} onClick={() => setEstado(1)} onMouseEnter={() => setHover1(true)} onMouseLeave={() => setHover1(false)}>
                            <img className={style.icon} src={imagen1} alt="" />
                        </div>
                    </div>
                    <div className={style.divOption2}>
                        <div className={style.divOption}>
                            <p className={estado === 2 ? style.optionSelected : style.option} onClick={() => setEstado(2)} onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>{t("Services.ThirdPart.Software-development.title")}</p>
                        </div>
                        <div className={estado === 2 ? style.divIconSelected : hover2 ? style.divIconHover : style.divIcon} onClick={() => setEstado(2)} onMouseEnter={() => setHover2(true)} onMouseLeave={() => setHover2(false)}>
                            <img className={style.icon} src={imagen2} alt="" />
                        </div>
                    </div>
                    <div className={style.divOption3}>
                        <div className={style.divOption}>
                            <p className={estado === 3 ? style.optionSelected : style.option} onClick={() => setEstado(3)} onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)}>{t("Services.ThirdPart.Web-developments.title")}</p>
                        </div>
                        <div className={estado === 3 ? style.divIconSelected : hover3 ? style.divIconHover : style.divIcon} onClick={() => setEstado(3)} onMouseEnter={() => setHover3(true)} onMouseLeave={() => setHover3(false)}>
                            <img className={style.icon} src={imagen3} alt="" />
                        </div>
                    </div>
                    <div className={style.divOption4}>
                        <div className={style.divOption}>
                            <p className={estado === 4 ? style.optionSelected : style.option} onClick={() => setEstado(4)} onMouseEnter={() => setHover4(true)} onMouseLeave={() => setHover4(false)}>{t("Services.ThirdPart.Updates.title")}</p>
                        </div>
                        <div className={estado === 4 ? style.divIconSelected : hover4 ? style.divIconHover : style.divIcon} onClick={() => setEstado(4)} onMouseEnter={() => setHover4(true)} onMouseLeave={() => setHover4(false)}>
                            <img className={style.icon} src={imagen4} alt="" />
                        </div>
                    </div>
                    <div className={style.divOption5}>
                        <div className={style.divOption}>
                            <p className={estado === 5 ? style.optionSelected : style.option} onClick={() => setEstado(5)} onMouseEnter={() => setHover5(true)} onMouseLeave={() => setHover5(false)}>{t("Services.ThirdPart.Medium.title")}</p>
                        </div>
                        <div className={estado === 5 ? style.divIconSelected : hover5 ? style.divIconHover : style.divIcon} onClick={() => setEstado(5)} onMouseEnter={() => setHover5(true)} onMouseLeave={() => setHover5(false)}>
                            <img className={style.icon} src={imagen5} alt="" />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default TerceraParte