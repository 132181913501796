import React, { useEffect, useState } from 'react'
import style from './Navbar.module.css'
import logo from '../../utils/images/logo.png'
import logoBr from '../../utils/images/logo-brasil.png'
import { useTranslation } from 'react-i18next';
import { AiOutlineArrowUp } from 'react-icons/ai'
import { Link } from 'react-router-dom';
import NavbarResp from './NavbarResp';

let flag = false

const Navbar = () => {
  const [t, i18n] = useTranslation();
  const [modalIdioma, setModalIdioma] = useState(false)

  window.document.title = `IngerTec | ${t("HomePage.digital-solutions")}`

  window.onclick = () => {
    setModalIdioma(false)
  }

  const changeToSpanish = () => {
    i18n.changeLanguage('es')
  };
  const changeToEnglish = () => {
    i18n.changeLanguage('en')
  };
  const changeToPortuguese = () => {
    i18n.changeLanguage('pt')
  };


  if (!flag) {
    flag = true

    if (window.location.origin === "https://pagina-ingertec-2.vercel.app") {
      changeToPortuguese()
    }

  }

  return (
    <>
      {
        window.location.pathname === '/politicas.html' ?
          <div className={style.navbar}>
            <div className={style.goTop} onClick={() => window.scrollTo(0, 0)}>
              <AiOutlineArrowUp className={style.arrow} />
            </div>
            <div className={style.navbarIzq}>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/" : 'https://ingertec.com.ar/#/'} className={style.link}>
                {
                  window.location.origin === "https://pagina-ingertec-2.vercel.app" ?
                    <img className={style.logo} src={logoBr} alt="" /> :
                    <img className={style.logo} src={logo} alt="" />
                }
              </Link>
            </div>

            <div className={style.navbarDerA}>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/" : 'https://ingertec.com.ar/#/'} className={style.link}>
                <p className={style.text}>{t("Navbar.home")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/nosotros" : 'https://ingertec.com.ar/#/nosotros'} className={style.link}>
                <p className={style.text}>{t("Navbar.us")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/clientes" : 'https://ingertec.com.ar/#/clientes'} className={style.link}>
                <p className={style.text}>{t("Navbar.clients")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/productos" : 'https://ingertec.com.ar/#/productos'} className={style.link}>
                <p className={style.text}>{t("Navbar.products")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/servicios" : 'https://ingertec.com.ar/#/servicios'} className={style.link}>
                <p className={style.text}>{t("Navbar.services")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/cursos" : 'https://ingertec.com.ar/#/cursos'} className={style.link}>
                <p className={style.text}>{t("Navbar.elearning")}</p>
              </Link>
              <Link to={window.location.origin === "https://pagina-ingertec-2.vercel.app" ? "https://ingertec.com.br/#/contacto" : 'https://ingertec.com.ar/#/contacto'} className={style.link}>
                <p className={style.text}>{t("Navbar.contact")}</p>
              </Link>
              <div className={style.divIdioma}>
                <p className={style.text} onClick={() => setTimeout(() => setModalIdioma(!modalIdioma), 5)}>{t("Navbar.language")}</p>
                <div className={modalIdioma ? style.trianguloTrue : style.trianguloFalse}></div>
                <div className={modalIdioma ? style.modalIdiomaTrue : style.modalIdiomaFalse}>
                  <p className={style.idiomaOption} onClick={() => { changeToSpanish(); setModalIdioma(false) }}>Español</p>
                  <p className={style.idiomaOption} onClick={() => { changeToEnglish(); setModalIdioma(false) }}>English</p>
                  <p className={style.idiomaOption} style={{ paddingBottom: '1vh' }} onClick={() => { changeToPortuguese(); setModalIdioma(false) }}>Português</p>
                </div>
              </div>
            </div>

            <NavbarResp />

          </div>
          :
          <div className={style.navbar}>
            <div className={style.goTop} onClick={() => window.scrollTo(0, 0)}>
              <AiOutlineArrowUp className={style.arrow} />
            </div>
            <div className={style.navbarIzq}>
              <Link to='/' className={style.link}>
                {
                  window.location.origin === "https://pagina-ingertec-2.vercel.app" ?
                    <img className={style.logo} src={logoBr} alt="" /> :
                    <img className={style.logo} src={logo} alt="" />
                }
              </Link>
            </div>

            <div className={style.navbarDerA}>
              <Link to='/' className={style.link}>
                <p className={style.text}>{t("Navbar.home")}</p>
              </Link>
              <Link to='/nosotros' className={style.link}>
                <p className={style.text}>{t("Navbar.us")}</p>
              </Link>
              <Link to='/clientes' className={style.link}>
                <p className={style.text}>{t("Navbar.clients")}</p>
              </Link>
              <Link to='/productos' className={style.link}>
                <p className={style.text}>{t("Navbar.products")}</p>
              </Link>
              <Link to='/servicios' className={style.link}>
                <p className={style.text}>{t("Navbar.services")}</p>
              </Link>
              <Link to='/cursos' className={style.link}>
                <p className={style.text}>{t("Navbar.elearning")}</p>
              </Link>
              <Link to='/contacto' className={style.link}>
                <p className={style.text}>{t("Navbar.contact")}</p>
              </Link>
              <div className={style.divIdioma}>
                <p className={style.text} onClick={() => setTimeout(() => setModalIdioma(!modalIdioma), 5)}>{t("Navbar.language")}</p>
                <div className={modalIdioma ? style.trianguloTrue : style.trianguloFalse}></div>
                <div className={modalIdioma ? style.modalIdiomaTrue : style.modalIdiomaFalse}>
                  <p className={style.idiomaOption} onClick={() => { changeToSpanish(); setModalIdioma(false) }}>Español</p>
                  <p className={style.idiomaOption} onClick={() => { changeToEnglish(); setModalIdioma(false) }}>English</p>
                  <p className={style.idiomaOption} style={{ paddingBottom: '1vh' }} onClick={() => { changeToPortuguese(); setModalIdioma(false) }}>Português</p>
                </div>
              </div>
            </div>

            <NavbarResp />

          </div>
      }
    </>
  )
}

export default Navbar